import polyfill from "@/js/polyfills";

import "@/js/lib/svg-sprite";

import courseCompare from "@/components/molecules/course-compare";
import courseModules from "@/components/molecules/course-modules";
import courseSearchOverlay from "@/js/search/overlay";
import courseSearch from "@/js/search/inPage";
import faq from "@/components/organisms/clearing/clearing-faq";
import inPageNav from "@/components/organisms/shared/dynamic-in-page-nav";
import masthead from "@/components/molecules/masthead";
import mediaGallery from "@/components/molecules/media-gallery";
import modal from "@/components/molecules/modal";
import selfSelection from "@/components/molecules/clearing/self-selection";
import tier2Nav from "@/components/molecules/nav/tier2-nav";
import toc from "@/components/molecules/toc";

import "@/js/widgets/toggle";

import "@/assets/styles/shu-design-system.scss";

import initSHU from "@/js/lib/initSHU";

window.addEventListener("DOMContentLoaded", () => {
  polyfill().then(() => {
    initSHU(window.SHU, {
      courseCompare,
      courseModules,
      courseSearchOverlay,
      courseSearch,
      faq,
      inPageNav,
      masthead,
      mediaGallery,
      modal,
      selfSelection,
      tier2Nav,
      toc,
    });
  });
});
